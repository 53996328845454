/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$ServiceID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeServiceCenter.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"MediumDesc":{"type":"string","maxLength":200},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrgUnitID":{"type":"integer"},"SCID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"primaryKey":"supplied","oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$ServiceID.json#"}]},"ShortDesc":{"type":"string","maxLength":500}},"$id":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeServiceCenter.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeServiceCenter.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
