/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$NotificationTypeID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$XeAppUserNotificationDelegate.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse.json#"},"Active":{"type":"boolean"},"AlertBody":{"type":"string","maxLength":1000},"AlertFrequency":{"type":"string","maxLength":16},"AlertSubject":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FolderID":{"type":"integer","primaryKey":"generated"},"FolderName":{"type":"string","maxLength":60},"IsAlertEnabled":{"type":"boolean"},"IsDelegateActive":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NotificationTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$NotificationTypeID.json#"}]},"PriorityID":{"type":"string","maxLength":16},"ResourceID":{"type":"integer"},"XeAppUserNotificationDelegate":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$XeAppUserNotificationDelegate.json#"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
