/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.assets.AssetXNetFile.json';
import schema_1 from './com.thrasys.xnet.assets.AssetXeReportGroups.json';
import schema_2 from './com.thrasys.xnet.assets.AssetXeReportType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.assets.AssetXeReportInfo.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AssetName":{"type":"string","maxLength":50},"BookID":{"type":"string","maxLength":64},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DefaultOutPut":{"type":"string","maxLength":1},"Description":{"type":"string","maxLength":500},"FieldTransforms":{"type":"string","maxLength":100},"Fields":{"type":"string","maxLength":400},"FileID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXNetFile.json#"}]},"FileName":{"type":"string","maxLength":200},"FilterValue":{"type":"string","maxLength":60},"GenerateLog":{"type":"string","maxLength":1},"GroupID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportGroups.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"OutputType":{"type":"string","maxLength":10},"Parameters":{"type":"string","maxLength":200},"Permissions":{"type":"string","maxLength":200},"Printer":{"type":"string","maxLength":50},"Query":{"type":"string","maxLength":500},"ReportCode":{"type":"string","maxLength":128},"ReportID":{"type":"integer","primaryKey":"generated"},"ReportTypeID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportType.json#"}]},"SystemCodes":{"type":"string","maxLength":50},"TemplateType":{"defaultValue":"JRXML","type":"string","maxLength":10},"Url":{"type":"string","maxLength":200}},"required":["Active"],"$id":"com.thrasys.xnet.assets.AssetXeReportInfo.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.assets.AssetXeReportInfo.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
