/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json#"},"AccessUpdateAction":{"type":"string","maxLength":20},"Captcha":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CurrentPassword":{"type":"string"},"Data":{"type":"string"},"DoSKey":{"type":"string"},"Email":{"type":"string"},"ErrorCode":{"type":"string"},"IncludeInDirectory":{"defaultValue":false,"type":"boolean"},"IsAcceptedTerms":{"type":"boolean"},"IsLocked":{"defaultValue":false,"type":"boolean"},"IsValid":{"type":"boolean"},"Length":{"type":"string"},"LoginName":{"type":"string"},"MethodID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Password":{"type":"string"},"PasswordExpiration":{"maximum":9999999999,"type":"integer"},"PasswordHash":{"type":"string"},"Phone":{"type":"string"},"PushToken":{"type":"string"},"SendAlert":{"type":"boolean"},"TermsAcceptDate":{"format":"date","nullable":true,"type":"string"},"TermsID":{"type":"integer"},"UDID":{"type":"string"},"Username":{"type":"string","primaryKey":"supplied","maxLength":60},"ValidateOnly":{"type":"boolean"},"WellKnownID":{"type":"string"},"logException":{"type":"boolean"}},"$id":"com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
