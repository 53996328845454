/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.smartbookcategory.XeSmartBookCategory$XeSmartBook.json#"},"AttrType":{"type":"string","maxLength":24},"BookID":{"type":"string","primaryKey":"generated","maxLength":64},"BookTypeID":{"type":"string","maxLength":30},"CategoryID":{"type":"string","primaryKey":"supplied","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":2000},"Sequence":{"exclusiveMaximum":100000,"type":"number"}},"$id":"com.thrasys.xnet.app.xmlobjects.smartbookcategory.XeSmartBookCategory$XeSmartBook.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.smartbookcategory.XeSmartBookCategory$XeSmartBook.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
