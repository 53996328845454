/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffSearchRequest$XeStaffAddress.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"type":"string","maxLength":16},"ContactInfoTypeIDNot":{"type":"string"},"ContactInfoTypeIDOperator":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"IsPreferred":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Phone":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20},"ZipPostalCodeOperator":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffSearchRequest$XeStaffAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchRequest$XeStaffAddress.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
