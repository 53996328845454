/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$Asset.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AssetGroup.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AccessRights.json#"},"Asset":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$Asset.json#"},"type":"array"},"AssetGroup":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AssetGroup.json#"},"type":"array"},"ResetAccountAccess":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AccessRights.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AccessRights.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
