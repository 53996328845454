/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeServiceInfoType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeServiceInfo.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Info":{"type":"string","maxLength":2000},"InfoID":{"type":"integer","primaryKey":"generated"},"InfoLink":{"type":"string","maxLength":300},"InfoTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.app.assets.AssetXeServiceInfoType.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"}},"required":["Active"],"$id":"com.thrasys.xnet.app.assets.AssetXeServiceInfo.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeServiceInfo.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
