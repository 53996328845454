/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeUnit.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeAppTerms.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ApplicationTerms":{"type":"string","maxLength":2147483647},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Duration":{"maximum":9999999999,"type":"integer"},"DurationUnitID":{"oneOf":[{"nullable":true,"type":"string","maxLength":64},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUnit.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"TermsActiveDate":{"format":"date","nullable":true,"type":"string"},"TermsID":{"type":"integer","primaryKey":"generated"},"WellKnownID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.app.assets.AssetXeAppTerms.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeAppTerms.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
