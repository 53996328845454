/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$ServiceCodeCatalogID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeOrgCatalogs.json#"},"Active":{"type":"boolean"},"As":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrgCatalogsID":{"type":"integer","primaryKey":"generated"},"ServiceCodeCatalogID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$ServiceCodeCatalogID.json#"}]}},"$id":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeOrgCatalogs.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeOrgCatalogs.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
