/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeCountry.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeLocationType.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeOrgUnit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeLocation.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"CountryID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeCountry.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Email":{"type":"string","maxLength":60},"EnterpriseID":{"type":"string"},"ExternalID":{"type":"string","maxLength":200},"Fax":{"type":"string","maxLength":30},"LocationID":{"type":"integer","primaryKey":"generated"},"LocationTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeLocationType.json#"}]},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"Notes":{"type":"string","maxLength":50},"OrgUnitID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeOrgUnit.json#"}]},"Phone":{"type":"string","maxLength":30},"StateProvince":{"type":"string","maxLength":100},"TTY":{"type":"string","maxLength":30},"WebSite":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"required":["Active"],"$id":"com.thrasys.xnet.app.assets.AssetXeLocation.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeLocation.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
