/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppMenus.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json#"},"AccountID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Delta":{"type":"string","maxLength":2147483647},"EnterpriseID":{"type":"string"},"MenuNodeID":{"primaryKey":"supplied","oneOf":[{"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppMenus.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Username":{"primaryKey":"supplied","oneOf":[{"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUserData.json#"}]}},"required":["Delta"],"$id":"com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
