/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$ResourceID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$FolderID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"FolderID":{"type":"integer","primaryKey":"generated"},"FolderName":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NotificationTypeID":{"type":"string","maxLength":60},"ResourceID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$ResourceID.json#"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$FolderID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$FolderID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
