/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$SpecialtyID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffSpecialty.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"SpecialtyID":{"primaryKey":"supplied","oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$SpecialtyID.json#"}]},"SpecialtyIDInline":{"type":"string"},"SpecialtyRank":{"type":"string","maxLength":16},"StaffID":{"type":"integer","primaryKey":"supplied"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffSpecialty.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffSpecialty.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
