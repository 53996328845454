/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$FileID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$XeAppUserNotificationAttachment.json#"},"FileID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$FileID.json#"},"FileIDInline":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$XeAppUserNotificationAttachment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$XeAppUserNotificationAttachment.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
