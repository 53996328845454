/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest$XeAppUserNotificationDelegate.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest.json#"},"Active":{"type":"boolean"},"AlertBody":{"type":"string","maxLength":1000},"AlertFrequency":{"type":"string","maxLength":16},"AlertSubject":{"type":"string","maxLength":200},"Category":{"type":"string","maxLength":1},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FolderID":{"type":"integer","primaryKey":"generated"},"IsAlertEnabled":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NotificationTypeID":{"type":"string","maxLength":60},"PriorityID":{"type":"string","maxLength":16},"ResourceID":{"type":"integer"},"Username":{"type":"string"},"WithDelegates":{"type":"boolean"},"XeAppUserNotificationDelegate":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest$XeAppUserNotificationDelegate.json#"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
