/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$RoleID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XgAppUserNotificationStatus.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$DerivedRecipientResID.json#"},"Active":{"type":"string","maxLength":1},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"ItemID":{"type":"integer"},"NameTitleID":{"type":"string","maxLength":16},"ResourceID":{"type":"integer","primaryKey":"generated"},"RoleID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$RoleID.json#"},"SecondName":{"type":"string","maxLength":100},"XgAppUserNotificationStatus":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XgAppUserNotificationStatus.json#"}]}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$DerivedRecipientResID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$DerivedRecipientResID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
