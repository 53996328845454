/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse$XeAppSystemNotification.json#"},"Body":{"type":"string","maxLength":2147483647},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ExpireDateTime":{"format":"date-time","nullable":true,"type":"string"},"ExpireOnRestart":{"type":"boolean"},"MimeType":{"type":"string","maxLength":100},"NotificationID":{"type":"integer","primaryKey":"generated"},"SenderAccID":{"maximum":9999999999,"type":"integer"},"SenderResID":{"type":"integer"},"SenderRoleID":{"type":"string","maxLength":60},"SentDate":{"format":"date-time","nullable":true,"type":"string"},"Subject":{"type":"string","maxLength":300}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse$XeAppSystemNotification.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse$XeAppSystemNotification.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
