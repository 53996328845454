/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$HealthPlanID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeHealthPlanOrgUnit.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"HealthPlanID":{"primaryKey":"supplied","oneOf":[{"nullable":true,"maximum":9007199254740991,"type":"integer"},{"$ref":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$HealthPlanID.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrgUnitID":{"type":"integer","primaryKey":"supplied"}},"$id":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeHealthPlanOrgUnit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeHealthPlanOrgUnit.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
