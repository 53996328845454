/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeWorkType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"FeatureID":{"type":"string","maxLength":100},"IsDead":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentWorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]},"ProcessDefinition":{"type":"string","maxLength":2147483647},"Version":{"maximum":99,"type":"integer"},"WellKnownID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json#"}]},"WorkTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":20},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkType.json#"}]},"WorkflowID":{"type":"integer","primaryKey":"generated"}},"required":["Active","IsDead"],"$id":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
