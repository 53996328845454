/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.refdata.XeAppReferenceData.json#"},"Active":{"type":"boolean"},"AssetDisplayColumn":{"type":"string"},"AssetIDColumn":{"type":"string"},"AssetName":{"type":"string"},"AssetSortColumn":{"type":"string"},"FeatureAlias":{"type":"string"},"FilterIDColumn":{"type":"string"},"FilterIDValue":{"type":"string"},"IsCached":{"type":"boolean"},"IsMasterData":{"type":"boolean"},"Name":{"type":"string"},"Prompt":{"type":"string"},"ReferenceDataCategoryID":{"type":"string"},"ReferenceDataID":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.refdata.XeAppReferenceData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.refdata.XeAppReferenceData.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
