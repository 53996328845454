/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest$XeAppUserNotificationDelegate.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EndDate":{"format":"date","nullable":true,"type":"string"},"FolderID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"StartDate":{"format":"date","nullable":true,"type":"string"},"Username":{"type":"string","primaryKey":"supplied","maxLength":60}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest$XeAppUserNotificationDelegate.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest$XeAppUserNotificationDelegate.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
