/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffAddress.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"type":"string","maxLength":16},"ContactInfoTypeIDFilter":{"type":"string"},"ContactInfoTypeIDOperator":{"type":"string"},"ContactName":{"type":"string","maxLength":100},"ContactPhone":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Email":{"type":"string","maxLength":60},"EmailOperator":{"type":"string"},"EnterpriseID":{"type":"string"},"Fax":{"type":"string","maxLength":30},"IsPreferred":{"type":"boolean"},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Phone":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"RestrictValue":{"maximum":99999,"type":"integer"},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$XeStaffAddress.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
