/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffSpecialty.json#"},"AccountID":{"type":"string"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string"},"NameAsset":{"type":"string"},"NameInlined":{"type":"string"},"SpecialtyID":{"type":"integer","primaryKey":"supplied"},"SpecialtyRank":{"type":"string","maxLength":16},"StaffID":{"type":"integer","primaryKey":"supplied"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffSpecialty.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffSpecialty.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
