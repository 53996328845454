/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffCertification.json#"},"AccountID":{"type":"string"},"Active":{"type":"boolean"},"Certification":{"type":"string","maxLength":50},"CertificationID":{"type":"integer","primaryKey":"generated"},"CertificationTypeID":{"type":"string","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Region":{"type":"string","maxLength":50},"StaffID":{"type":"integer"},"ValidityEnd":{"format":"date","nullable":true,"type":"string"},"ValidityStart":{"format":"date","nullable":true,"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffCertification.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse$XeStaffCertification.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
