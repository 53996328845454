/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.assets.AssetXNetFile.json#"},"CloudID":{"type":"string","maxLength":220},"CompressedSize":{"defaultValue":0,"maximum":9999999999,"type":"integer"},"ExternalID":{"type":"string","maxLength":220},"FileContents":{"type":"string","maxLength":2147483647},"FileID":{"type":"integer","primaryKey":"supplied"},"FileMAC":{"defaultValue":"*","type":"string","maxLength":60},"FileName":{"type":"string","maxLength":200},"FileSize":{"maximum":9999999999,"type":"integer"},"FileTime":{"format":"date-time","nullable":true,"type":"string"},"IsEncrypted":{"defaultValue":false,"type":"boolean"},"IsTemporary":{"defaultValue":false,"type":"boolean"},"StorageID":{"type":"string","maxLength":200},"Username":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.assets.AssetXNetFile.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.assets.AssetXNetFile.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
