/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeCountry.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CountryID":{"type":"string","primaryKey":"supplied","maxLength":16},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"HasStateProvince":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"Sequence":{"defaultValue":400,"maximum":999,"type":"integer"}},"required":["Active","Code","HasStateProvince","Sequence"],"$id":"com.thrasys.xnet.app.assets.AssetXeCountry.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeCountry.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
