/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$XeVisitAssessment.json#"},"Active":{"type":"boolean"},"AssessmentID":{"type":"integer"},"AssessmentSubTypeID":{"type":"string","maxLength":32},"AssessmentTypeID":{"type":"string","maxLength":32},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"Status":{"type":"string","maxLength":32},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$XeVisitAssessment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$XeVisitAssessment.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
