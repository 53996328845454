/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$FolderID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$RoleID.json';
import schema_2 from './com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$StatusID.json';
import schema_3 from './com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$XeAppUserNotification.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json#"},"CompletedDate":{"format":"date-time","nullable":true,"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"FolderID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$FolderID.json#"},"IsCompleted":{"type":"boolean"},"IsDisplayed":{"type":"boolean"},"IsFlagged":{"type":"boolean"},"IsSelected":{"type":"string"},"ItemID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"ReadDate":{"format":"date-time","nullable":true,"type":"string"},"RoleID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$RoleID.json#"},"SendDate":{"format":"date-time","nullable":true,"type":"string"},"StatusID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$StatusID.json#"}]},"XeAppUserNotification":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse$XeAppUserNotification.json#"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
