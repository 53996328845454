/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData$XeResource.json#"},"CalNotify":{"defaultValue":false,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"DoB":{"format":"date-time","nullable":true,"type":"string"},"Email":{"type":"string","maxLength":60},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsEmailInvalid":{"defaultValue":false,"type":"boolean"},"MethodID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"Phone":{"type":"string","maxLength":30},"ResourceID":{"type":"integer","primaryKey":"generated"},"ResourceTypeID":{"type":"string","maxLength":50},"SecondName":{"type":"string","maxLength":100},"SecureEmail":{"type":"string","maxLength":60}},"required":["FamilyName","GivenName"],"$id":"com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData$XeResource.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData$XeResource.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
