/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest$XeStaffAddress.json#"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"type":"string","maxLength":16},"Email":{"type":"string","maxLength":60},"Fax":{"type":"string","maxLength":30},"IsPreferred":{"type":"boolean"},"Phone":{"type":"string","maxLength":30},"State":{"type":"string"},"ZipPostalCode":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest$XeStaffAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest$XeStaffAddress.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
