/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"},"Abbr":{"type":"string","maxLength":20},"Active":{"defaultValue":true,"type":"boolean"},"CDACodeCategory":{"type":"string","maxLength":50},"CDAIsBaseCode":{"defaultValue":false,"type":"boolean"},"CodeCategory":{"type":"string","maxLength":50},"CodeTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"CodeVersion":{"type":"string","maxLength":50},"CodingStandard":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataUpdateDate":{"format":"date","nullable":true,"type":"string"},"IsBaseCode":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"VersionDate":{"format":"date","nullable":true,"type":"string"}},"required":["Active","CDAIsBaseCode","CodeCategory","CodeVersion","CodingStandard","IsBaseCode"],"$id":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
