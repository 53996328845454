/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeLocationType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"LocationTypeID":{"type":"string","primaryKey":"supplied","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeLocationType.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.app.assets.AssetXeLocationType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeLocationType.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
