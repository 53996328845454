/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeContactInfoType.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeCountry.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeStaff.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeStaffAddress.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"AddressID":{"type":"string","primaryKey":"generated","maxLength":32},"AddressLine1":{"type":"string","maxLength":200},"AddressLine2":{"type":"string","maxLength":200},"AddressLine3":{"type":"string","maxLength":200},"City":{"type":"string","maxLength":100},"ContactInfoTypeID":{"oneOf":[{"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeContactInfoType.json#"}]},"ContactName":{"type":"string","maxLength":100},"ContactPhone":{"type":"string","maxLength":50},"Coordinates":{"type":"string"},"CountryID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeCountry.json#"}]},"CountyFIPS":{"type":"string","maxLength":20},"CountyName":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Email":{"type":"string","maxLength":60},"EnterpriseID":{"type":"string"},"Fax":{"type":"string","maxLength":30},"IsPreferred":{"defaultValue":false,"type":"boolean"},"MobilePhone":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Notes":{"type":"string","maxLength":500},"Phone":{"type":"string","maxLength":30},"StaffID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeStaff.json#"}]},"StaffID_EnterpriseID":{"type":"string"},"StateFIPS":{"type":"string","maxLength":20},"StateProvince":{"type":"string","maxLength":100},"ZipPostalCode":{"type":"string","maxLength":20}},"required":["Active","IsPreferred"],"$id":"com.thrasys.xnet.app.assets.AssetXeStaffAddress.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeStaffAddress.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
