/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeUserData.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeEnterpriseType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeEnterpriseData.json#"},"Abbr":{"type":"string","maxLength":15},"AccountID":{"type":"integer"},"AdminUser":{"oneOf":[{"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUserData.json#"}]},"BillingOrg":{"type":"string","maxLength":20},"BirstSSOPassword":{"type":"string","maxLength":100},"BirstSpaceID":{"type":"string","maxLength":100},"CPOEStatus":{"defaultValue":"NONE","type":"string","maxLength":10},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataRestrictValue":{"defaultValue":0,"maximum":99999,"type":"integer"},"EnterpriseID":{"type":"string"},"EnterpriseName":{"type":"string","maxLength":100},"EnterpriseTypeID":{"oneOf":[{"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeEnterpriseType.json#"}]},"ExternalID":{"type":"string","maxLength":100},"IsOperational":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"NationalProviderID":{"type":"string","maxLength":10},"SecureEmailUsername":{"oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUserData.json#"}]},"TaxIdNumber":{"type":"string","maxLength":20},"UserRestrictValue":{"defaultValue":0,"maximum":99999,"type":"integer"}},"required":["CPOEStatus","EnterpriseName","IsOperational"],"$id":"com.thrasys.xnet.app.assets.AssetXeEnterpriseData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeEnterpriseData.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
