/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.enterprisedata.UserEnterpriseData.json#"},"Abbr":{"type":"string","maxLength":15},"AccountID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string","primaryKey":"supplied","maxLength":30},"EnterpriseName":{"type":"string","maxLength":100},"EnterpriseTypeID":{"type":"string","maxLength":30},"IsDefault":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"RelationTypeID":{"type":"string","maxLength":30},"UserAccount":{"type":"boolean"}},"$id":"com.thrasys.xnet.app.xmlobjects.enterprisedata.UserEnterpriseData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.enterprisedata.UserEnterpriseData.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
