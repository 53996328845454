/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$NameTitleID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$ResourceID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string"},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string"},"GivenName":{"type":"string"},"Join":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"$ref":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$NameTitleID.json#"},"ResourceID":{"type":"integer"},"SecondName":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$ResourceID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$ResourceID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
