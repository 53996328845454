/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeCodedCategory.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CategoryID":{"type":"string","primaryKey":"supplied","maxLength":60},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataFilter":{"type":"string","maxLength":100},"Description":{"type":"string","maxLength":500},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentID":{"oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeCodedCategory.json#"}]},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Active"],"$id":"com.thrasys.xnet.app.assets.AssetXeCodedCategory.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeCodedCategory.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
