/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse$XeAppSystemNotification.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"FolderID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Unread":{"type":"string"},"UnreadChat":{"type":"string"},"UnreadEnterpriseString":{"type":"string"},"UnreadEvent":{"type":"string"},"UnreadMessage":{"type":"string"},"UnreadRecent":{"type":"string"},"UnreadRecentChat":{"type":"string"},"UnreadRecentEnterpriseString":{"type":"string"},"UnreadRecentEvent":{"type":"string"},"UnreadRecentMessage":{"type":"string"},"XeAppSystemNotification":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse$XeAppSystemNotification.json#"},"type":"array"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
