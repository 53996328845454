/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppFeatures.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeAppRefData.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AssetDisplayColumn":{"type":"string","maxLength":50},"AssetIDColumn":{"type":"string","maxLength":50},"AssetName":{"type":"string","maxLength":50},"AssetSortColumn":{"type":"string","maxLength":50},"ClassName":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"FeatureAlias":{"oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppFeatures.json#"}]},"FilterIDColumn":{"type":"string","maxLength":50},"FilterIDValue":{"type":"string","maxLength":50},"IsCached":{"defaultValue":false,"type":"boolean"},"IsMasterData":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"Prompt":{"type":"string","maxLength":200},"Query":{"type":"string","maxLength":2147483647},"ReferenceDataCategoryID":{"type":"string","maxLength":32},"ReferenceDataID":{"type":"string","primaryKey":"supplied","maxLength":32}},"required":["Active","AssetDisplayColumn","AssetIDColumn","AssetName","ClassName","IsCached","IsMasterData","Query"],"$id":"com.thrasys.xnet.app.assets.AssetXeAppRefData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeAppRefData.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
