/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$ResourceID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$XeAppUserNotificationItem.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FolderID":{"type":"integer","primaryKey":"generated"},"FolderName":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NotificationTypeID":{"type":"string","maxLength":60},"NotificationTypeIDOperator":{"type":"string"},"ResourceID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$ResourceID.json#"},"Username":{"type":"string"},"XeAppUserNotificationItem":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$XeAppUserNotificationItem.json#"},"type":"array"}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
