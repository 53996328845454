/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest$XeUserData.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ResourceID":{"type":"integer"},"ResourceIDFilter":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest$XeUserData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest$XeUserData.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
