/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$FolderID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$RoleID.json';
import schema_2 from './com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XgAppUserNotificationStatus.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XeAppUserNotificationItem.json#"},"CompletedDate":{"format":"date-time","nullable":true,"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedIsUserItem":{"type":"string","maxLength":1},"FolderID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$FolderID.json#"},"IsCompleted":{"type":"boolean"},"IsDisplayed":{"type":"boolean"},"IsFlagged":{"type":"boolean"},"ItemID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NotificationID":{"type":"integer"},"RoleID":{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$RoleID.json#"},"SendDate":{"format":"date-time","nullable":true,"type":"string"},"XgAppUserNotificationStatus":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XgAppUserNotificationStatus.json#"}]}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XeAppUserNotificationItem.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail$XeAppUserNotificationItem.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
