/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataChangeAllPasswordsRequest.json#"},"IsSelfIncluded":{"type":"boolean"},"Password":{"type":"string"},"PasswordExpiration":{"maximum":9999999999,"type":"integer"}},"required":["Password"],"$id":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataChangeAllPasswordsRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataChangeAllPasswordsRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
