/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeWorkflowInstance.json#"},"AccountID":{"type":"integer"},"ContextVariables":{"type":"string","maxLength":2000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"HasEndedOrCleaned":{"defaultValue":false,"type":"boolean"},"HistoryID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"TokenStates":{"type":"string","maxLength":2000},"WorkID":{"type":"integer","primaryKey":"generated"},"WorkflowID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]}},"required":["HasEndedOrCleaned","HistoryID"],"$id":"com.thrasys.xnet.app.assets.AssetXeWorkflowInstance.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeWorkflowInstance.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
