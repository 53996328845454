/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$EnterpriseID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$XeResourceOrgUnit.json';
import schema_2 from './com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$XeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json#"},"AccountID":{"type":"string"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Credentials":{"type":"string","maxLength":100},"DEANumber":{"type":"string","maxLength":10},"Description":{"type":"string","maxLength":200},"EnterpriseID":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$EnterpriseID.json#"},"ExternalID":{"type":"string","maxLength":50},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsValid":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NationalProviderID":{"type":"string","maxLength":10},"ResourceTypeID":{"type":"string","maxLength":50},"RestrictValue":{"maximum":99999,"type":"integer"},"SecondName":{"type":"string","maxLength":100},"SignatureFileID":{"type":"integer"},"StaffID":{"type":"integer","primaryKey":"supplied"},"StaffMasterID":{"type":"string","maxLength":128},"XeResourceOrgUnit":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$XeResourceOrgUnit.json#"},"type":"array"},"XeUserData":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse$XeUserData.json#"}},"required":["ExternalID","ResourceTypeID"],"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
