/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$EnterpriseID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$NameTitleID.json';
import schema_2 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$ResourceTypeID.json';
import schema_3 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$StaffID.json';
import schema_4 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$XeStaffAddress.json';
import schema_5 from './com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$XeStaffSpecialty.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse.json#"},"Active":{"type":"boolean"},"AuthLevel":{"maximum":9,"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Credentials":{"type":"string","maxLength":100},"DEANumber":{"type":"string","maxLength":10},"EnterpriseID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$EnterpriseID.json#"}]},"ExternalID":{"type":"string","maxLength":50},"FacilityID":{"type":"integer"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsValid":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NameTitleID":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$NameTitleID.json#"},"NationalProviderID":{"type":"string","maxLength":10},"ResourceTypeID":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$ResourceTypeID.json#"},"RestrictValue":{"maximum":99999,"type":"integer"},"SecondName":{"type":"string","maxLength":100},"StaffID":{"primaryKey":"supplied","oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$StaffID.json#"}]},"StaffMasterID":{"type":"string","maxLength":128},"XeStaffAddress":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$XeStaffAddress.json#"},"type":"array"},"XeStaffSpecialty":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse$XeStaffSpecialty.json#"},"type":"array"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
