/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$Username.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$XeAppUserNotificationDelegate.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DelegateActive":{"type":"string"},"EndDate":{"format":"date","nullable":true,"type":"string"},"FolderID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"StartDate":{"format":"date","nullable":true,"type":"string"},"Username":{"primaryKey":"supplied","oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$Username.json#"}]}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$XeAppUserNotificationDelegate.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse$XeAppUserNotificationDelegate.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
