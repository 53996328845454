/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeCalendar.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXgAppUserNotificationMethod.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeNameTitle.json';
import schema_3 from './com.thrasys.xnet.app.assets.AssetXeOrgUnit.json';
import schema_4 from './com.thrasys.xnet.app.assets.AssetXeResourceType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeResource.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"CalNotify":{"defaultValue":false,"type":"boolean"},"CalendarID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeCalendar.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Credentials":{"type":"string","maxLength":100},"Email":{"type":"string","maxLength":60},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IsEmailInvalid":{"defaultValue":false,"type":"boolean"},"IsExternal":{"defaultValue":false,"type":"boolean"},"IsValid":{"defaultValue":true,"type":"boolean"},"MethodID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXgAppUserNotificationMethod.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeNameTitle.json#"}]},"OrgUnitID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeOrgUnit.json#"}]},"Phone":{"type":"string","maxLength":30},"ResourceID":{"type":"integer","primaryKey":"generated"},"ResourceTypeID":{"oneOf":[{"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeResourceType.json#"}]},"SecondName":{"type":"string","maxLength":100},"SecureEmail":{"type":"string","maxLength":60}},"required":["Active","CalNotify","IsEmailInvalid","IsExternal","IsValid"],"$id":"com.thrasys.xnet.app.assets.AssetXeResource.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeResource.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
