/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsUserResponse.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"DEANumber":{"type":"string","maxLength":10},"Description":{"type":"string","maxLength":200},"Email":{"type":"string","maxLength":60},"ExternalID":{"type":"string","maxLength":50},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"MethodID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NationalProviderID":{"type":"string","maxLength":10},"Password":{"type":"string","maxLength":44},"PasswordConfirm":{"type":"string","maxLength":44},"Phone":{"type":"string","maxLength":30},"ResourceID":{"type":"integer"},"ResourceTypeID":{"type":"string","maxLength":50},"RestrictValue":{"maximum":99999,"type":"integer"},"SecondName":{"type":"string","maxLength":100},"StaffID":{"type":"integer","primaryKey":"supplied"},"StaffMasterID":{"type":"string","maxLength":128},"Username":{"type":"string","maxLength":60},"XeUserData":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json#"},"type":"array"}},"required":["ExternalID","ResourceID","ResourceTypeID","StaffMasterID"],"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffAsUserResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffAsUserResponse.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
