/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$ResourceID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$XeUserDataView.json#"},"As":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"Join":{"type":"string"},"LoginName":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ResourceID":{"$ref":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$ResourceID.json#"},"ResourceIDJoin":{"type":"string"},"Username":{"type":"string","primaryKey":"supplied","maxLength":30},"UsernameFilter":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$XeUserDataView.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$XeUserDataView.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
