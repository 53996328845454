/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$DerivedRecipientResID.json#"},"Active":{"type":"string","maxLength":1},"EnterpriseID":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"ItemID":{"type":"integer"},"NameTitleID":{"type":"string","maxLength":16},"ResourceID":{"type":"integer","primaryKey":"generated"},"SecondName":{"type":"string","maxLength":100}},"$id":"com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$DerivedRecipientResID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse$DerivedRecipientResID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
