/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeEIS_Staff.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DoB":{"format":"date","nullable":true,"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IdentityMerged":{"type":"string","maxLength":5},"IdentityStatus":{"type":"string","maxLength":10},"IsMergeTarget":{"type":"string","maxLength":5},"Master":{"type":"string","primaryKey":"generated","maxLength":128},"MatchInfo":{"type":"string","maxLength":256},"MergeReviewNeeded":{"type":"string","maxLength":5},"MergedTargetID":{"type":"string","maxLength":128},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"NationalProviderID":{"type":"string","maxLength":10},"ProfileID":{"type":"string","maxLength":2000},"SexID":{"type":"string","maxLength":16},"TaxIdNumber":{"type":"string","maxLength":20}},"$id":"com.thrasys.xnet.app.assets.AssetXeEIS_Staff.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeEIS_Staff.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
