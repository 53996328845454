/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AccessRights.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$XeAccountGroup.json';
import schema_2 from './com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$XeEnterpriseData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess.json#"},"AccessRights":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$AccessRights.json#"},"AccessRightsResetAccountAccess":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Username":{"type":"string","primaryKey":"supplied","maxLength":60},"XeAccountGroup":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$XeAccountGroup.json#"},"type":"array"},"XeEnterpriseData":{"items":{"$ref":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess$XeEnterpriseData.json#"},"type":"array"}},"$id":"com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
