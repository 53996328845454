/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppModules.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.assets.AssetXeAppFeatures.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ClassName":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"FeatureAlias":{"type":"string","primaryKey":"supplied","maxLength":60},"FeatureID":{"type":"string","maxLength":200},"HtmlComponentName":{"type":"string","maxLength":60},"IsCustomMenu":{"defaultValue":false,"type":"boolean"},"IsMenuItem":{"defaultValue":false,"type":"boolean"},"IsReportCanvas":{"defaultValue":false,"type":"boolean"},"IsTemplateItem":{"defaultValue":false,"type":"boolean"},"IsTransitionElement":{"defaultValue":false,"type":"boolean"},"IsUserTrailItem":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ModuleID":{"oneOf":[{"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppModules.json#"}]},"Name":{"type":"string","maxLength":100}},"required":["Active","IsCustomMenu","IsMenuItem","IsReportCanvas","IsTemplateItem","IsTransitionElement","IsUserTrailItem"],"$id":"com.thrasys.xnet.app.assets.AssetXeAppFeatures.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.assets.AssetXeAppFeatures.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
