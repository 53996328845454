/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeStaffAddress.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeStaff.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Credentials":{"type":"string","maxLength":100},"DEANumber":{"type":"string","maxLength":10},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"GivenNameFilter":{"type":"string"},"IsValid":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"NationalProviderID":{"type":"string","maxLength":10},"ResourceTypeID":{"type":"string","maxLength":50},"SecondName":{"type":"string","maxLength":100},"StaffID":{"type":"integer","primaryKey":"supplied"},"XeStaffAddress":{"$ref":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeStaffAddress.json#"}},"required":["FamilyName","ResourceTypeID"],"$id":"com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeStaff.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse$XeStaff.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
