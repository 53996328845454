/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$StaffID.json#"},"Email":{"type":"string","maxLength":60},"Inline":{"type":"string"},"IsExternal":{"type":"boolean"},"MethodID":{"type":"string","maxLength":16},"Operator":{"type":"string"},"Phone":{"type":"string","maxLength":30},"ResourceID":{"type":"integer","primaryKey":"generated"},"ResourceIDAs":{"type":"string"},"ResourceTypeID":{"type":"string","maxLength":50},"ResourceTypeIDOperator":{"type":"string"}},"$id":"com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$StaffID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest$StaffID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
