/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$EnterpriseID.json';
import schema_1 from './com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$XeUserDataView.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory.json#"},"ClientIPAddress":{"type":"string"},"ClientPlatform":{"type":"string"},"ClientVersion":{"type":"string"},"EnterpriseID":{"$ref":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$EnterpriseID.json#"},"LastLoginDate":{"format":"date-time","nullable":true,"type":"string"},"LastLogoutDate":{"format":"date-time","nullable":true,"type":"string"},"LastLogoutReason":{"type":"string"},"QuerySort":{"type":"string"},"UDID":{"type":"string"},"UserAgent":{"type":"string"},"Username":{"type":"string"},"UsernameOperator":{"type":"string"},"XeUserDataView":{"$ref":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory$XeUserDataView.json#"}},"$id":"com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
