/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.assets.AssetXeRuleFact.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.assets.AssetXeRuleInfo.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"FactID":{"oneOf":[{"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.assets.AssetXeRuleFact.json#"}]},"FilePath":{"type":"string","maxLength":300},"GroupID":{"type":"string","maxLength":200},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"RuleData":{"type":"string","maxLength":2000},"RuleInfoID":{"type":"string","primaryKey":"generated","maxLength":32},"RuleScript":{"type":"string","maxLength":2147483647}},"required":["Active","GroupID","Name"],"$id":"com.thrasys.xnet.assets.AssetXeRuleInfo.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.assets.AssetXeRuleInfo.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
